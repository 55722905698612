/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../generated/types.generated';

import { EscrowOrderFieldsFragmentDoc, TimelineItemFieldsFragmentDoc } from '../../timeline/timeline.generated';
import { AccountFieldsFragmentDoc } from '../../account/accounts.generated';
import { BasicPageInfoFieldsFragmentDoc } from '../../../graphql/fragments/basic-page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type EscrowOrderQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type EscrowOrderQuery = {
  __typename?: 'Query';
  escrowOrder: {
    __typename?: 'EscrowOrder';
    id: string;
    message?: string | null;
    escrowScript: string;
    escrowAddress: string;
    nonce: string;
    releaseTxid?: string | null;
    returnTxid?: string | null;
    price: number;
    amount: number;
    createdAt: any;
    updatedAt: any;
    escrowOrderStatus: Types.EscrowOrderStatus;
    arbitratorAccount: {
      __typename?: 'Account';
      id: number;
      publicKey?: string | null;
      hash160?: string | null;
      telegramId?: string | null;
      telegramUsername?: string | null;
    };
    buyerAccount: {
      __typename?: 'Account';
      id: number;
      publicKey?: string | null;
      hash160?: string | null;
      telegramId?: string | null;
      telegramUsername?: string | null;
    };
    sellerAccount: {
      __typename?: 'Account';
      id: number;
      publicKey?: string | null;
      hash160?: string | null;
      telegramId?: string | null;
      telegramUsername?: string | null;
    };
    moderatorAccount: {
      __typename?: 'Account';
      id: number;
      publicKey?: string | null;
      hash160?: string | null;
      telegramId?: string | null;
      telegramUsername?: string | null;
    };
    paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
    escrowOffer: { __typename?: 'Offer'; postId: string; message: string };
    escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
    dispute?: { __typename?: 'Dispute'; createdBy: string; reason?: string | null; status: Types.DisputeStatus } | null;
  };
};

export type GetModeratorAccountQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetModeratorAccountQuery = {
  __typename?: 'Query';
  getModeratorAccount: {
    __typename?: 'Account';
    id: number;
    name: string;
    address: string;
    hash160?: string | null;
    mnemonicHash?: string | null;
    encryptedMnemonic?: string | null;
    encryptedSecret?: string | null;
    publicKey?: string | null;
    language: string;
    followersCount?: number | null;
    followingsCount?: number | null;
    followingPagesCount?: number | null;
    totalDanaViewScore?: number | null;
    website?: string | null;
    description?: string | null;
    createCommentFee?: string | null;
    createdAt: any;
    updatedAt: any;
    avatar?: string | null;
    cover?: string | null;
    rankNumber?: number | null;
    rankScore?: number | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
    pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
    accountDana?: {
      __typename?: 'AccountDana';
      danaGiven?: number | null;
      danaReceived?: number | null;
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
    } | null;
  };
};

export type GetRandomArbitratorAccountQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetRandomArbitratorAccountQuery = {
  __typename?: 'Query';
  getRandomArbitratorAccount: {
    __typename?: 'Account';
    id: number;
    name: string;
    address: string;
    hash160?: string | null;
    mnemonicHash?: string | null;
    encryptedMnemonic?: string | null;
    encryptedSecret?: string | null;
    publicKey?: string | null;
    language: string;
    followersCount?: number | null;
    followingsCount?: number | null;
    followingPagesCount?: number | null;
    totalDanaViewScore?: number | null;
    website?: string | null;
    description?: string | null;
    createCommentFee?: string | null;
    createdAt: any;
    updatedAt: any;
    avatar?: string | null;
    cover?: string | null;
    rankNumber?: number | null;
    rankScore?: number | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
    pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
    accountDana?: {
      __typename?: 'AccountDana';
      danaGiven?: number | null;
      danaReceived?: number | null;
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
    } | null;
  };
};

export type AllEscrowOrderByAccountQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  escrowOrderStatus: Types.EscrowOrderStatus;
}>;

export type AllEscrowOrderByAccountQuery = {
  __typename?: 'Query';
  allEscrowOrderByAccount: {
    __typename?: 'TimelineItemConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'TimelineItemBasicEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineItem';
        id: string;
        data:
          | {
              __typename: 'Dispute';
              id: string;
              reason?: string | null;
              createdBy: string;
              createdAt: any;
              updatedAt: any;
              disputeStatus: Types.DisputeStatus;
              escrowOrder: {
                __typename?: 'EscrowOrder';
                amount: number;
                id: string;
                buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                offer: {
                  __typename?: 'Offer';
                  message: string;
                  createdAt: any;
                  country?: { __typename?: 'Country'; name: string } | null;
                  state?: { __typename?: 'State'; name?: string | null } | null;
                };
              };
            }
          | {
              __typename: 'EscrowOrder';
              id: string;
              message?: string | null;
              escrowScript: string;
              escrowAddress: string;
              nonce: string;
              releaseTxid?: string | null;
              returnTxid?: string | null;
              price: number;
              amount: number;
              createdAt: any;
              updatedAt: any;
              escrowOrderStatus: Types.EscrowOrderStatus;
              arbitratorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              buyerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              sellerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              moderatorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
              escrowOffer: { __typename?: 'Offer'; postId: string; message: string };
              escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
              dispute?: {
                __typename?: 'Dispute';
                createdBy: string;
                reason?: string | null;
                status: Types.DisputeStatus;
              } | null;
            }
          | {
              __typename: 'Post';
              id: string;
              content: string;
              accountId: number;
              pageId?: string | null;
              tokenId?: string | null;
              repostCount: number;
              totalComments: number;
              commentableId?: string | null;
              createdAt: any;
              updatedAt: any;
              followPostOwner?: boolean | null;
              followedPage?: boolean | null;
              followedToken?: boolean | null;
              bookmarkableId?: string | null;
              isBookmarked?: boolean | null;
              originalLanguage?: string | null;
              danaViewScore?: number | null;
              burnedByOthers?: boolean | null;
              account: {
                __typename?: 'Account';
                address: string;
                hash160?: string | null;
                publicKey?: string | null;
                id: number;
                name: string;
                avatar?: string | null;
                createCommentFee?: string | null;
                telegramUsername?: string | null;
              };
              page?: {
                __typename?: 'Page';
                avatar?: string | null;
                name: string;
                id: string;
                createPostFee: string;
                createCommentFee: string;
                pageAccount: {
                  __typename?: 'Account';
                  id: number;
                  name: string;
                  address: string;
                  hash160?: string | null;
                };
              } | null;
              token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
              reposts?: Array<{
                __typename?: 'Repost';
                accountId?: number | null;
                account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
              }> | null;
              dana?: {
                __typename?: 'PostDana';
                danaBurnUp: number;
                danaBurnDown: number;
                danaBurnScore: number;
                danaReceivedUp: number;
                danaReceivedDown: number;
                danaReceivedScore: number;
                version: number;
              } | null;
              boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
              translations?: Array<{
                __typename?: 'PostTranslation';
                id: string;
                translateContent?: string | null;
                translateLanguage?: string | null;
              }> | null;
              imageUploadable?: {
                __typename?: 'ImageUploadable';
                id: string;
                uploads: Array<{
                  __typename?: 'Upload';
                  id: string;
                  sha: string;
                  bucket?: string | null;
                  width?: number | null;
                  height?: number | null;
                  cfImageId?: string | null;
                  cfImageFilename?: string | null;
                }>;
              } | null;
              poll?: {
                __typename?: 'Poll';
                postId: string;
                question: string;
                startDate: any;
                endDate: any;
                canAddOption: boolean;
                singleSelect: boolean;
                defaultOptions?: Array<string> | null;
                totalVote?: number | null;
                options: Array<{
                  __typename?: 'PollOption';
                  id: string;
                  option: string;
                  pollId: string;
                  danaScoreOption?: number | null;
                  pollAnswerOnAccount?: Array<{
                    __typename?: 'PollAnswerOnAccount';
                    pollDanaScore: number;
                    accountId: number;
                  }> | null;
                }>;
              } | null;
              postOffer?: {
                __typename?: 'Offer';
                postId: string;
                publicKey: string;
                message: string;
                price: string;
                orderLimitMin: number;
                orderLimitMax: number;
                status: Types.OfferStatus;
                stateId?: number | null;
                countryId?: number | null;
                paymentMethods: Array<{
                  __typename?: 'OfferPaymentMethod';
                  paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
                }>;
                state?: { __typename?: 'State'; name?: string | null } | null;
                country?: { __typename?: 'Country'; name: string } | null;
              } | null;
            };
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type AllEscrowOrderByOfferIdQueryVariables = Types.Exact<{
  offerId: Types.Scalars['String']['input'];
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type AllEscrowOrderByOfferIdQuery = {
  __typename?: 'Query';
  allEscrowOrderByOfferId: {
    __typename?: 'TimelineItemConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'TimelineItemBasicEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineItem';
        id: string;
        data:
          | {
              __typename: 'Dispute';
              id: string;
              reason?: string | null;
              createdBy: string;
              createdAt: any;
              updatedAt: any;
              disputeStatus: Types.DisputeStatus;
              escrowOrder: {
                __typename?: 'EscrowOrder';
                amount: number;
                id: string;
                buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                offer: {
                  __typename?: 'Offer';
                  message: string;
                  createdAt: any;
                  country?: { __typename?: 'Country'; name: string } | null;
                  state?: { __typename?: 'State'; name?: string | null } | null;
                };
              };
            }
          | {
              __typename: 'EscrowOrder';
              id: string;
              message?: string | null;
              escrowScript: string;
              escrowAddress: string;
              nonce: string;
              releaseTxid?: string | null;
              returnTxid?: string | null;
              price: number;
              amount: number;
              createdAt: any;
              updatedAt: any;
              escrowOrderStatus: Types.EscrowOrderStatus;
              arbitratorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              buyerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              sellerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              moderatorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
              escrowOffer: { __typename?: 'Offer'; postId: string; message: string };
              escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
              dispute?: {
                __typename?: 'Dispute';
                createdBy: string;
                reason?: string | null;
                status: Types.DisputeStatus;
              } | null;
            }
          | {
              __typename: 'Post';
              id: string;
              content: string;
              accountId: number;
              pageId?: string | null;
              tokenId?: string | null;
              repostCount: number;
              totalComments: number;
              commentableId?: string | null;
              createdAt: any;
              updatedAt: any;
              followPostOwner?: boolean | null;
              followedPage?: boolean | null;
              followedToken?: boolean | null;
              bookmarkableId?: string | null;
              isBookmarked?: boolean | null;
              originalLanguage?: string | null;
              danaViewScore?: number | null;
              burnedByOthers?: boolean | null;
              account: {
                __typename?: 'Account';
                address: string;
                hash160?: string | null;
                publicKey?: string | null;
                id: number;
                name: string;
                avatar?: string | null;
                createCommentFee?: string | null;
                telegramUsername?: string | null;
              };
              page?: {
                __typename?: 'Page';
                avatar?: string | null;
                name: string;
                id: string;
                createPostFee: string;
                createCommentFee: string;
                pageAccount: {
                  __typename?: 'Account';
                  id: number;
                  name: string;
                  address: string;
                  hash160?: string | null;
                };
              } | null;
              token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
              reposts?: Array<{
                __typename?: 'Repost';
                accountId?: number | null;
                account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
              }> | null;
              dana?: {
                __typename?: 'PostDana';
                danaBurnUp: number;
                danaBurnDown: number;
                danaBurnScore: number;
                danaReceivedUp: number;
                danaReceivedDown: number;
                danaReceivedScore: number;
                version: number;
              } | null;
              boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
              translations?: Array<{
                __typename?: 'PostTranslation';
                id: string;
                translateContent?: string | null;
                translateLanguage?: string | null;
              }> | null;
              imageUploadable?: {
                __typename?: 'ImageUploadable';
                id: string;
                uploads: Array<{
                  __typename?: 'Upload';
                  id: string;
                  sha: string;
                  bucket?: string | null;
                  width?: number | null;
                  height?: number | null;
                  cfImageId?: string | null;
                  cfImageFilename?: string | null;
                }>;
              } | null;
              poll?: {
                __typename?: 'Poll';
                postId: string;
                question: string;
                startDate: any;
                endDate: any;
                canAddOption: boolean;
                singleSelect: boolean;
                defaultOptions?: Array<string> | null;
                totalVote?: number | null;
                options: Array<{
                  __typename?: 'PollOption';
                  id: string;
                  option: string;
                  pollId: string;
                  danaScoreOption?: number | null;
                  pollAnswerOnAccount?: Array<{
                    __typename?: 'PollAnswerOnAccount';
                    pollDanaScore: number;
                    accountId: number;
                  }> | null;
                }>;
              } | null;
              postOffer?: {
                __typename?: 'Offer';
                postId: string;
                publicKey: string;
                message: string;
                price: string;
                orderLimitMin: number;
                orderLimitMax: number;
                status: Types.OfferStatus;
                stateId?: number | null;
                countryId?: number | null;
                paymentMethods: Array<{
                  __typename?: 'OfferPaymentMethod';
                  paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
                }>;
                state?: { __typename?: 'State'; name?: string | null } | null;
                country?: { __typename?: 'Country'; name: string } | null;
              } | null;
            };
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type UserRequestTelegramChatQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type UserRequestTelegramChatQuery = { __typename?: 'Query'; userRequestTelegramChat: boolean };

export type ArbiRequestTelegramChatQueryVariables = Types.Exact<{
  requestChatPublicKey: Types.Scalars['String']['input'];
  escrowOrderId: Types.Scalars['String']['input'];
}>;

export type ArbiRequestTelegramChatQuery = { __typename?: 'Query'; arbiRequestTelegramChat: boolean };

export type CreateEscrowOrderMutationVariables = Types.Exact<{
  input: Types.CreateEscrowOrderInput;
}>;

export type CreateEscrowOrderMutation = {
  __typename?: 'Mutation';
  createEscrowOrder: { __typename?: 'EscrowOrder'; id: string };
};

export type UpdateEscrowOrderStatusMutationVariables = Types.Exact<{
  input: Types.UpdateEscrowOrderInput;
}>;

export type UpdateEscrowOrderStatusMutation = {
  __typename?: 'Mutation';
  updateEscrowOrderStatus: {
    __typename?: 'EscrowOrder';
    id: string;
    message?: string | null;
    price: number;
    amount: number;
    status: Types.EscrowOrderStatus;
    createdAt: any;
    updatedAt: any;
  };
};

export const EscrowOrderDocument = `
    query EscrowOrder($id: String!) {
  escrowOrder(id: $id) {
    ...EscrowOrderFields
  }
}
    ${EscrowOrderFieldsFragmentDoc}`;
export const GetModeratorAccountDocument = `
    query GetModeratorAccount {
  getModeratorAccount {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;
export const GetRandomArbitratorAccountDocument = `
    query GetRandomArbitratorAccount {
  getRandomArbitratorAccount {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;
export const AllEscrowOrderByAccountDocument = `
    query AllEscrowOrderByAccount($first: Int = 20, $after: String, $escrowOrderStatus: EscrowOrderStatus!) {
  allEscrowOrderByAccount(
    first: $first
    after: $after
    escrowOrderStatus: $escrowOrderStatus
  ) {
    totalCount
    edges {
      cursor
      node {
        ...TimelineItemFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${TimelineItemFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const AllEscrowOrderByOfferIdDocument = `
    query AllEscrowOrderByOfferId($offerId: String!, $first: Int = 20, $after: String) {
  allEscrowOrderByOfferId(first: $first, after: $after, offerId: $offerId) {
    totalCount
    edges {
      cursor
      node {
        ...TimelineItemFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${TimelineItemFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const UserRequestTelegramChatDocument = `
    query UserRequestTelegramChat($id: String!) {
  userRequestTelegramChat(id: $id)
}
    `;
export const ArbiRequestTelegramChatDocument = `
    query ArbiRequestTelegramChat($requestChatPublicKey: String!, $escrowOrderId: String!) {
  arbiRequestTelegramChat(
    requestChatPublicKey: $requestChatPublicKey
    escrowOrderId: $escrowOrderId
  )
}
    `;
export const CreateEscrowOrderDocument = `
    mutation CreateEscrowOrder($input: CreateEscrowOrderInput!) {
  createEscrowOrder(data: $input) {
    id
  }
}
    `;
export const UpdateEscrowOrderStatusDocument = `
    mutation UpdateEscrowOrderStatus($input: UpdateEscrowOrderInput!) {
  updateEscrowOrderStatus(data: $input) {
    id
    message
    price
    amount
    status
    createdAt
    updatedAt
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    EscrowOrder: build.query<EscrowOrderQuery, EscrowOrderQueryVariables>({
      query: variables => ({ document: EscrowOrderDocument, variables })
    }),
    GetModeratorAccount: build.query<GetModeratorAccountQuery, GetModeratorAccountQueryVariables | void>({
      query: variables => ({ document: GetModeratorAccountDocument, variables })
    }),
    GetRandomArbitratorAccount: build.query<
      GetRandomArbitratorAccountQuery,
      GetRandomArbitratorAccountQueryVariables | void
    >({
      query: variables => ({ document: GetRandomArbitratorAccountDocument, variables })
    }),
    AllEscrowOrderByAccount: build.query<AllEscrowOrderByAccountQuery, AllEscrowOrderByAccountQueryVariables>({
      query: variables => ({ document: AllEscrowOrderByAccountDocument, variables })
    }),
    AllEscrowOrderByOfferId: build.query<AllEscrowOrderByOfferIdQuery, AllEscrowOrderByOfferIdQueryVariables>({
      query: variables => ({ document: AllEscrowOrderByOfferIdDocument, variables })
    }),
    UserRequestTelegramChat: build.query<UserRequestTelegramChatQuery, UserRequestTelegramChatQueryVariables>({
      query: variables => ({ document: UserRequestTelegramChatDocument, variables })
    }),
    ArbiRequestTelegramChat: build.query<ArbiRequestTelegramChatQuery, ArbiRequestTelegramChatQueryVariables>({
      query: variables => ({ document: ArbiRequestTelegramChatDocument, variables })
    }),
    CreateEscrowOrder: build.mutation<CreateEscrowOrderMutation, CreateEscrowOrderMutationVariables>({
      query: variables => ({ document: CreateEscrowOrderDocument, variables })
    }),
    UpdateEscrowOrderStatus: build.mutation<UpdateEscrowOrderStatusMutation, UpdateEscrowOrderStatusMutationVariables>({
      query: variables => ({ document: UpdateEscrowOrderStatusDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
