/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { TimelineItemFieldsFragmentDoc } from '../timeline/timeline.generated';
import { BasicPageInfoFieldsFragmentDoc } from '../../graphql/fragments/basic-page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type BookmarkFieldsFragment = {
  __typename?: 'Bookmark';
  id: string;
  bookmarkableId: string;
  createdAt?: any | null;
  updatedAt?: any | null;
  account: { __typename?: 'Account'; address: string; id: number; name: string };
};

export type BookmarkQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type BookmarkQuery = {
  __typename?: 'Query';
  bookmark: {
    __typename?: 'Bookmark';
    id: string;
    bookmarkableId: string;
    createdAt?: any | null;
    updatedAt?: any | null;
    account: { __typename?: 'Account'; address: string; id: number; name: string };
  };
};

export type BookmarkTimelineQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  id: Types.Scalars['Int']['input'];
}>;

export type BookmarkTimelineQuery = {
  __typename?: 'Query';
  bookmarkTimeline: {
    __typename?: 'TimelineItemConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'TimelineItemBasicEdge';
      cursor: string;
      node: {
        __typename?: 'TimelineItem';
        id: string;
        data:
          | {
              __typename: 'Dispute';
              id: string;
              reason?: string | null;
              createdBy: string;
              createdAt: any;
              updatedAt: any;
              disputeStatus: Types.DisputeStatus;
              escrowOrder: {
                __typename?: 'EscrowOrder';
                amount: number;
                id: string;
                buyerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                sellerAccount: { __typename?: 'Account'; telegramUsername?: string | null };
                offer: {
                  __typename?: 'Offer';
                  message: string;
                  createdAt: any;
                  country?: { __typename?: 'Country'; name: string } | null;
                  state?: { __typename?: 'State'; name?: string | null } | null;
                };
              };
            }
          | {
              __typename: 'EscrowOrder';
              id: string;
              message?: string | null;
              escrowScript: string;
              escrowAddress: string;
              nonce: string;
              releaseTxid?: string | null;
              returnTxid?: string | null;
              price: number;
              amount: number;
              createdAt: any;
              updatedAt: any;
              escrowOrderStatus: Types.EscrowOrderStatus;
              arbitratorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              buyerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              sellerAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              moderatorAccount: {
                __typename?: 'Account';
                id: number;
                publicKey?: string | null;
                hash160?: string | null;
                telegramId?: string | null;
                telegramUsername?: string | null;
              };
              paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
              escrowOffer: { __typename?: 'Offer'; postId: string; message: string };
              escrowTxids?: Array<{ __typename?: 'EscrowTxid'; txid: string; value: any; outIdx: number }> | null;
              dispute?: {
                __typename?: 'Dispute';
                createdBy: string;
                reason?: string | null;
                status: Types.DisputeStatus;
              } | null;
            }
          | {
              __typename: 'Post';
              id: string;
              content: string;
              accountId: number;
              pageId?: string | null;
              tokenId?: string | null;
              repostCount: number;
              totalComments: number;
              commentableId?: string | null;
              createdAt: any;
              updatedAt: any;
              followPostOwner?: boolean | null;
              followedPage?: boolean | null;
              followedToken?: boolean | null;
              bookmarkableId?: string | null;
              isBookmarked?: boolean | null;
              originalLanguage?: string | null;
              danaViewScore?: number | null;
              burnedByOthers?: boolean | null;
              account: {
                __typename?: 'Account';
                address: string;
                hash160?: string | null;
                publicKey?: string | null;
                id: number;
                name: string;
                avatar?: string | null;
                createCommentFee?: string | null;
                telegramUsername?: string | null;
              };
              page?: {
                __typename?: 'Page';
                avatar?: string | null;
                name: string;
                id: string;
                createPostFee: string;
                createCommentFee: string;
                pageAccount: {
                  __typename?: 'Account';
                  id: number;
                  name: string;
                  address: string;
                  hash160?: string | null;
                };
              } | null;
              token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
              reposts?: Array<{
                __typename?: 'Repost';
                accountId?: number | null;
                account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
              }> | null;
              dana?: {
                __typename?: 'PostDana';
                danaBurnUp: number;
                danaBurnDown: number;
                danaBurnScore: number;
                danaReceivedUp: number;
                danaReceivedDown: number;
                danaReceivedScore: number;
                version: number;
              } | null;
              boostScore?: { __typename?: 'PostBoost'; boostScore: number } | null;
              translations?: Array<{
                __typename?: 'PostTranslation';
                id: string;
                translateContent?: string | null;
                translateLanguage?: string | null;
              }> | null;
              imageUploadable?: {
                __typename?: 'ImageUploadable';
                id: string;
                uploads: Array<{
                  __typename?: 'Upload';
                  id: string;
                  sha: string;
                  bucket?: string | null;
                  width?: number | null;
                  height?: number | null;
                  cfImageId?: string | null;
                  cfImageFilename?: string | null;
                }>;
              } | null;
              poll?: {
                __typename?: 'Poll';
                postId: string;
                question: string;
                startDate: any;
                endDate: any;
                canAddOption: boolean;
                singleSelect: boolean;
                defaultOptions?: Array<string> | null;
                totalVote?: number | null;
                options: Array<{
                  __typename?: 'PollOption';
                  id: string;
                  option: string;
                  pollId: string;
                  danaScoreOption?: number | null;
                  pollAnswerOnAccount?: Array<{
                    __typename?: 'PollAnswerOnAccount';
                    pollDanaScore: number;
                    accountId: number;
                  }> | null;
                }>;
              } | null;
              postOffer?: {
                __typename?: 'Offer';
                postId: string;
                publicKey: string;
                message: string;
                price: string;
                orderLimitMin: number;
                orderLimitMax: number;
                status: Types.OfferStatus;
                stateId?: number | null;
                countryId?: number | null;
                paymentMethods: Array<{
                  __typename?: 'OfferPaymentMethod';
                  paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
                }>;
                state?: { __typename?: 'State'; name?: string | null } | null;
                country?: { __typename?: 'Country'; name: string } | null;
              } | null;
            };
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type CreateBookmarkMutationVariables = Types.Exact<{
  input: Types.CreateBookmarkInput;
}>;

export type CreateBookmarkMutation = {
  __typename?: 'Mutation';
  createBookmark: {
    __typename?: 'Bookmark';
    id: string;
    bookmarkableId: string;
    createdAt?: any | null;
    updatedAt?: any | null;
    account: { __typename?: 'Account'; address: string; id: number; name: string };
  };
};

export type RemoveBookmarkMutationVariables = Types.Exact<{
  input: Types.RemoveBookmarkInput;
}>;

export type RemoveBookmarkMutation = {
  __typename?: 'Mutation';
  removeBookmark: {
    __typename?: 'Bookmark';
    id: string;
    bookmarkableId: string;
    createdAt?: any | null;
    updatedAt?: any | null;
    account: { __typename?: 'Account'; address: string; id: number; name: string };
  };
};

export const BookmarkFieldsFragmentDoc = `
    fragment BookmarkFields on Bookmark {
  id
  account {
    address
    id
    name
  }
  bookmarkableId
  createdAt
  updatedAt
}
    `;
export const BookmarkDocument = `
    query Bookmark($id: String!) {
  bookmark(id: $id) {
    ...BookmarkFields
  }
}
    ${BookmarkFieldsFragmentDoc}`;
export const BookmarkTimelineDocument = `
    query BookmarkTimeline($first: Int = 20, $after: String, $id: Int!) {
  bookmarkTimeline(first: $first, after: $after, id: $id) {
    totalCount
    edges {
      cursor
      node {
        ...TimelineItemFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${TimelineItemFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const CreateBookmarkDocument = `
    mutation CreateBookmark($input: CreateBookmarkInput!) {
  createBookmark(data: $input) {
    ...BookmarkFields
  }
}
    ${BookmarkFieldsFragmentDoc}`;
export const RemoveBookmarkDocument = `
    mutation RemoveBookmark($input: RemoveBookmarkInput!) {
  removeBookmark(data: $input) {
    ...BookmarkFields
  }
}
    ${BookmarkFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    Bookmark: build.query<BookmarkQuery, BookmarkQueryVariables>({
      query: variables => ({ document: BookmarkDocument, variables })
    }),
    BookmarkTimeline: build.query<BookmarkTimelineQuery, BookmarkTimelineQueryVariables>({
      query: variables => ({ document: BookmarkTimelineDocument, variables })
    }),
    CreateBookmark: build.mutation<CreateBookmarkMutation, CreateBookmarkMutationVariables>({
      query: variables => ({ document: CreateBookmarkDocument, variables })
    }),
    RemoveBookmark: build.mutation<RemoveBookmarkMutation, RemoveBookmarkMutationVariables>({
      query: variables => ({ document: RemoveBookmarkDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
