/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { BasicPageInfoFieldsFragmentDoc } from '../../graphql/fragments/basic-page-info-fields.fragment.generated';
import { api } from '@store/baseApi';
export type AccountQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;

export type AccountQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: number;
    name: string;
    address: string;
    hash160?: string | null;
    mnemonicHash?: string | null;
    encryptedMnemonic?: string | null;
    encryptedSecret?: string | null;
    publicKey?: string | null;
    language: string;
    followersCount?: number | null;
    followingsCount?: number | null;
    followingPagesCount?: number | null;
    totalDanaViewScore?: number | null;
    website?: string | null;
    description?: string | null;
    createCommentFee?: string | null;
    createdAt: any;
    updatedAt: any;
    avatar?: string | null;
    cover?: string | null;
    rankNumber?: number | null;
    rankScore?: number | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
    pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
    accountDana?: {
      __typename?: 'AccountDana';
      danaGiven?: number | null;
      danaReceived?: number | null;
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
    } | null;
  };
};

export type GetAccountByAddressQueryVariables = Types.Exact<{
  address: Types.Scalars['String']['input'];
}>;

export type GetAccountByAddressQuery = {
  __typename?: 'Query';
  getAccountByAddress: {
    __typename?: 'Account';
    id: number;
    name: string;
    address: string;
    hash160?: string | null;
    mnemonicHash?: string | null;
    encryptedMnemonic?: string | null;
    encryptedSecret?: string | null;
    publicKey?: string | null;
    language: string;
    followersCount?: number | null;
    followingsCount?: number | null;
    followingPagesCount?: number | null;
    totalDanaViewScore?: number | null;
    website?: string | null;
    description?: string | null;
    createCommentFee?: string | null;
    createdAt: any;
    updatedAt: any;
    avatar?: string | null;
    cover?: string | null;
    rankNumber?: number | null;
    rankScore?: number | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
    pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
    accountDana?: {
      __typename?: 'AccountDana';
      danaGiven?: number | null;
      danaReceived?: number | null;
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
    } | null;
  };
};

export type AllFollowersByPageQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id: Types.Scalars['String']['input'];
}>;

export type AllFollowersByPageQuery = {
  __typename?: 'Query';
  allFollowersByPage: {
    __typename?: 'AccountBasicConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'AccountBasicEdge';
      cursor: string;
      node: {
        __typename?: 'Account';
        id: number;
        name: string;
        address: string;
        hash160?: string | null;
        mnemonicHash?: string | null;
        encryptedMnemonic?: string | null;
        encryptedSecret?: string | null;
        publicKey?: string | null;
        language: string;
        followersCount?: number | null;
        followingsCount?: number | null;
        followingPagesCount?: number | null;
        totalDanaViewScore?: number | null;
        website?: string | null;
        description?: string | null;
        createCommentFee?: string | null;
        createdAt: any;
        updatedAt: any;
        avatar?: string | null;
        cover?: string | null;
        rankNumber?: number | null;
        rankScore?: number | null;
        telegramId?: string | null;
        telegramUsername?: string | null;
        pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
        accountDana?: {
          __typename?: 'AccountDana';
          danaGiven?: number | null;
          danaReceived?: number | null;
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
        } | null;
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type AllFollowersByTokenQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  id: Types.Scalars['String']['input'];
}>;

export type AllFollowersByTokenQuery = {
  __typename?: 'Query';
  allFollowersByToken: {
    __typename?: 'AccountBasicConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'AccountBasicEdge';
      cursor: string;
      node: {
        __typename?: 'Account';
        id: number;
        name: string;
        address: string;
        hash160?: string | null;
        mnemonicHash?: string | null;
        encryptedMnemonic?: string | null;
        encryptedSecret?: string | null;
        publicKey?: string | null;
        language: string;
        followersCount?: number | null;
        followingsCount?: number | null;
        followingPagesCount?: number | null;
        totalDanaViewScore?: number | null;
        website?: string | null;
        description?: string | null;
        createCommentFee?: string | null;
        createdAt: any;
        updatedAt: any;
        avatar?: string | null;
        cover?: string | null;
        rankNumber?: number | null;
        rankScore?: number | null;
        telegramId?: string | null;
        telegramUsername?: string | null;
        pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
        accountDana?: {
          __typename?: 'AccountDana';
          danaGiven?: number | null;
          danaReceived?: number | null;
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
        } | null;
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type AccountsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type AccountsQuery = {
  __typename?: 'Query';
  allAccounts: {
    __typename?: 'AccountBasicConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'AccountBasicEdge';
      cursor: string;
      node: {
        __typename?: 'Account';
        id: number;
        name: string;
        address: string;
        hash160?: string | null;
        mnemonicHash?: string | null;
        encryptedMnemonic?: string | null;
        encryptedSecret?: string | null;
        publicKey?: string | null;
        language: string;
        followersCount?: number | null;
        followingsCount?: number | null;
        followingPagesCount?: number | null;
        totalDanaViewScore?: number | null;
        website?: string | null;
        description?: string | null;
        createCommentFee?: string | null;
        createdAt: any;
        updatedAt: any;
        avatar?: string | null;
        cover?: string | null;
        rankNumber?: number | null;
        rankScore?: number | null;
        telegramId?: string | null;
        telegramUsername?: string | null;
        pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
        accountDana?: {
          __typename?: 'AccountDana';
          danaGiven?: number | null;
          danaReceived?: number | null;
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
        } | null;
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type TopWeekAccountsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  week: Types.Scalars['Int']['input'];
  year: Types.Scalars['Int']['input'];
}>;

export type TopWeekAccountsQuery = {
  __typename?: 'Query';
  topWeekAccountDanaGiven: {
    __typename?: 'AccountBasicConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'AccountBasicEdge';
      cursor: string;
      node: {
        __typename?: 'Account';
        id: number;
        name: string;
        address: string;
        hash160?: string | null;
        mnemonicHash?: string | null;
        encryptedMnemonic?: string | null;
        encryptedSecret?: string | null;
        publicKey?: string | null;
        language: string;
        followersCount?: number | null;
        followingsCount?: number | null;
        followingPagesCount?: number | null;
        totalDanaViewScore?: number | null;
        website?: string | null;
        description?: string | null;
        createCommentFee?: string | null;
        createdAt: any;
        updatedAt: any;
        avatar?: string | null;
        cover?: string | null;
        rankNumber?: number | null;
        rankScore?: number | null;
        telegramId?: string | null;
        telegramUsername?: string | null;
        pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
        accountDana?: {
          __typename?: 'AccountDana';
          danaGiven?: number | null;
          danaReceived?: number | null;
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
        } | null;
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type TopMonthAccountsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  month: Types.Scalars['Int']['input'];
  year: Types.Scalars['Int']['input'];
}>;

export type TopMonthAccountsQuery = {
  __typename?: 'Query';
  topMonthAccountDanaGiven: {
    __typename?: 'AccountBasicConnection';
    totalCount: number;
    edges: Array<{
      __typename?: 'AccountBasicEdge';
      cursor: string;
      node: {
        __typename?: 'Account';
        id: number;
        name: string;
        address: string;
        hash160?: string | null;
        mnemonicHash?: string | null;
        encryptedMnemonic?: string | null;
        encryptedSecret?: string | null;
        publicKey?: string | null;
        language: string;
        followersCount?: number | null;
        followingsCount?: number | null;
        followingPagesCount?: number | null;
        totalDanaViewScore?: number | null;
        website?: string | null;
        description?: string | null;
        createCommentFee?: string | null;
        createdAt: any;
        updatedAt: any;
        avatar?: string | null;
        cover?: string | null;
        rankNumber?: number | null;
        rankScore?: number | null;
        telegramId?: string | null;
        telegramUsername?: string | null;
        pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
        accountDana?: {
          __typename?: 'AccountDana';
          danaGiven?: number | null;
          danaReceived?: number | null;
          danaBurnUp: number;
          danaBurnDown: number;
          danaBurnScore: number;
          danaReceivedUp: number;
          danaReceivedDown: number;
          danaReceivedScore: number;
        } | null;
      };
    }>;
    pageInfo: { __typename?: 'BasicPageInfo'; endCursor: string; hasNextPage: boolean };
  };
};

export type AccountFieldsFragment = {
  __typename?: 'Account';
  id: number;
  name: string;
  address: string;
  hash160?: string | null;
  mnemonicHash?: string | null;
  encryptedMnemonic?: string | null;
  encryptedSecret?: string | null;
  publicKey?: string | null;
  language: string;
  followersCount?: number | null;
  followingsCount?: number | null;
  followingPagesCount?: number | null;
  totalDanaViewScore?: number | null;
  website?: string | null;
  description?: string | null;
  createCommentFee?: string | null;
  createdAt: any;
  updatedAt: any;
  avatar?: string | null;
  cover?: string | null;
  rankNumber?: number | null;
  rankScore?: number | null;
  telegramId?: string | null;
  telegramUsername?: string | null;
  pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
  accountDana?: {
    __typename?: 'AccountDana';
    danaGiven?: number | null;
    danaReceived?: number | null;
    danaBurnUp: number;
    danaBurnDown: number;
    danaBurnScore: number;
    danaReceivedUp: number;
    danaReceivedDown: number;
    danaReceivedScore: number;
  } | null;
};

export type UpdateAccountMutationVariables = Types.Exact<{
  input: Types.UpdateAccountInput;
}>;

export type UpdateAccountMutation = {
  __typename?: 'Mutation';
  updateAccount: {
    __typename?: 'Account';
    id: number;
    name: string;
    address: string;
    hash160?: string | null;
    mnemonicHash?: string | null;
    encryptedMnemonic?: string | null;
    encryptedSecret?: string | null;
    publicKey?: string | null;
    language: string;
    followersCount?: number | null;
    followingsCount?: number | null;
    followingPagesCount?: number | null;
    totalDanaViewScore?: number | null;
    website?: string | null;
    description?: string | null;
    createCommentFee?: string | null;
    createdAt: any;
    updatedAt: any;
    avatar?: string | null;
    cover?: string | null;
    rankNumber?: number | null;
    rankScore?: number | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
    pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
    accountDana?: {
      __typename?: 'AccountDana';
      danaGiven?: number | null;
      danaReceived?: number | null;
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
    } | null;
  };
};

export type UpdateAccountTelegramUsernameMutationVariables = Types.Exact<{
  telegramId: Types.Scalars['String']['input'];
  telegramUsername: Types.Scalars['String']['input'];
}>;

export type UpdateAccountTelegramUsernameMutation = {
  __typename?: 'Mutation';
  updateAccountTelegramUsername: {
    __typename?: 'Account';
    id: number;
    name: string;
    address: string;
    hash160?: string | null;
    mnemonicHash?: string | null;
    encryptedMnemonic?: string | null;
    encryptedSecret?: string | null;
    publicKey?: string | null;
    language: string;
    followersCount?: number | null;
    followingsCount?: number | null;
    followingPagesCount?: number | null;
    totalDanaViewScore?: number | null;
    website?: string | null;
    description?: string | null;
    createCommentFee?: string | null;
    createdAt: any;
    updatedAt: any;
    avatar?: string | null;
    cover?: string | null;
    rankNumber?: number | null;
    rankScore?: number | null;
    telegramId?: string | null;
    telegramUsername?: string | null;
    pages?: Array<{ __typename?: 'Page'; id: string; name: string }> | null;
    accountDana?: {
      __typename?: 'AccountDana';
      danaGiven?: number | null;
      danaReceived?: number | null;
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
    } | null;
  };
};

export const AccountFieldsFragmentDoc = `
    fragment AccountFields on Account {
  id
  name
  address
  hash160
  mnemonicHash
  encryptedMnemonic
  encryptedSecret
  publicKey
  language
  pages {
    id
    name
  }
  followersCount
  followingsCount
  followingPagesCount
  totalDanaViewScore
  website
  description
  createCommentFee
  createdAt
  updatedAt
  avatar
  cover
  rankNumber
  rankScore
  accountDana {
    danaGiven
    danaReceived
    danaBurnUp
    danaBurnDown
    danaBurnScore
    danaReceivedUp
    danaReceivedDown
    danaReceivedScore
  }
  telegramId
  telegramUsername
}
    `;
export const AccountDocument = `
    query Account($id: Int!) {
  account(id: $id) {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;
export const GetAccountByAddressDocument = `
    query getAccountByAddress($address: String!) {
  getAccountByAddress(address: $address) {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;
export const AllFollowersByPageDocument = `
    query AllFollowersByPage($after: String, $first: Int = 20, $id: String!) {
  allFollowersByPage(after: $after, first: $first, id: $id) {
    totalCount
    edges {
      cursor
      node {
        ...AccountFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${AccountFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const AllFollowersByTokenDocument = `
    query AllFollowersByToken($after: String, $first: Int = 20, $id: String!) {
  allFollowersByToken(after: $after, first: $first, id: $id) {
    totalCount
    edges {
      cursor
      node {
        ...AccountFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${AccountFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const AccountsDocument = `
    query Accounts($after: String, $first: Int = 20) {
  allAccounts(after: $after, first: $first) {
    totalCount
    edges {
      cursor
      node {
        ...AccountFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${AccountFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const TopWeekAccountsDocument = `
    query topWeekAccounts($after: String, $first: Int = 20, $week: Int!, $year: Int!) {
  topWeekAccountDanaGiven(after: $after, first: $first, week: $week, year: $year) {
    totalCount
    edges {
      cursor
      node {
        ...AccountFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${AccountFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const TopMonthAccountsDocument = `
    query topMonthAccounts($after: String, $first: Int = 19, $month: Int!, $year: Int!) {
  topMonthAccountDanaGiven(
    after: $after
    first: $first
    month: $month
    year: $year
  ) {
    totalCount
    edges {
      cursor
      node {
        ...AccountFields
      }
    }
    pageInfo {
      ...BasicPageInfoFields
    }
  }
}
    ${AccountFieldsFragmentDoc}
${BasicPageInfoFieldsFragmentDoc}`;
export const UpdateAccountDocument = `
    mutation updateAccount($input: UpdateAccountInput!) {
  updateAccount(data: $input) {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;
export const UpdateAccountTelegramUsernameDocument = `
    mutation UpdateAccountTelegramUsername($telegramId: String!, $telegramUsername: String!) {
  updateAccountTelegramUsername(
    telegramId: $telegramId
    telegramUsername: $telegramUsername
  ) {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    Account: build.query<AccountQuery, AccountQueryVariables>({
      query: variables => ({ document: AccountDocument, variables })
    }),
    getAccountByAddress: build.query<GetAccountByAddressQuery, GetAccountByAddressQueryVariables>({
      query: variables => ({ document: GetAccountByAddressDocument, variables })
    }),
    AllFollowersByPage: build.query<AllFollowersByPageQuery, AllFollowersByPageQueryVariables>({
      query: variables => ({ document: AllFollowersByPageDocument, variables })
    }),
    AllFollowersByToken: build.query<AllFollowersByTokenQuery, AllFollowersByTokenQueryVariables>({
      query: variables => ({ document: AllFollowersByTokenDocument, variables })
    }),
    Accounts: build.query<AccountsQuery, AccountsQueryVariables | void>({
      query: variables => ({ document: AccountsDocument, variables })
    }),
    topWeekAccounts: build.query<TopWeekAccountsQuery, TopWeekAccountsQueryVariables>({
      query: variables => ({ document: TopWeekAccountsDocument, variables })
    }),
    topMonthAccounts: build.query<TopMonthAccountsQuery, TopMonthAccountsQueryVariables>({
      query: variables => ({ document: TopMonthAccountsDocument, variables })
    }),
    updateAccount: build.mutation<UpdateAccountMutation, UpdateAccountMutationVariables>({
      query: variables => ({ document: UpdateAccountDocument, variables })
    }),
    UpdateAccountTelegramUsername: build.mutation<
      UpdateAccountTelegramUsernameMutation,
      UpdateAccountTelegramUsernameMutationVariables
    >({
      query: variables => ({ document: UpdateAccountTelegramUsernameDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
